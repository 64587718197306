<template>
    <div>
        <v-row>
            <v-col cols="12">
                <v-card>
                     <v-btn
                            color="primary"
                            dark
                            
                            class="mb-5"
                            @click="newSegment">
                                Nuevo Segmento
                        </v-btn>
                    <v-subheader>Selecciona un cliente para editar sus segmentos</v-subheader>
                    <v-data-table
                        :headers="headers"
                        :items="Segments"
                        :loading="loadingSegments"                       
                        hide-default-footer
                        class="elevation-1">
                        <template v-slot:top>
                            <v-toolbar flat>
                                <v-toolbar-title>Segmentos por clientes</v-toolbar-title>
                                <v-spacer></v-spacer>
                            </v-toolbar>
                        </template>
                        <template v-slot:no-data>
                            No se encontraron registros.
                        </template>
                       <template v-slot:item.actions="{ item }">
                            <v-icon
                                small
                                class="mr-2"
                                @click="viewItem(item.id)"
                            >
                                mdi-pencil
                            </v-icon>
                            <v-icon
                                small
                                class="mr-2"
                                @click="deleteItem(item.id)"
                            >
                                mdi-delete
                            </v-icon>
                        </template>
                    </v-data-table>
                    <div class="text-center pt-2">
                        <v-pagination
                            v-model="currentPage"
                            :length="total_pages"
                            @input="handlePageChange"
                            :total-visible="10"
                        ></v-pagination>
                    </div>
                    
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>
<script>
import {misMixins} from '../../../vue/mixinsAlert';
export default {
    data: () => ({
        headers: [
        {
          text: 'Nombre del segmento',
          align: 'start',
          value: 'name_architecture',
        },
        { text: 'Dimension', value: 'name_dimension' },
        { text: 'Acciones', value: 'actions', sortable: false },
      ],
      
      loadingSegments: true,
      Segments: [],
      total_pages: 0,
      currentPage: 1

    }),

    mixins:[misMixins],

    methods: {
 
          handlePageChange(value) {
            this.currentPage = value;
            this.getSegments()
    },
        newSegment(){
            this.$router.push('newSegment')
        },
        

        createCliente(){
            this.$router.push({ name: 'CreateClient' });
            
        }
        ,viewItem(id){
            console.log('prueba id',id)
            this.$router.push({ name: 'Segment', params:{ id: id}});
        },
      
        deleteItem(id){

            this.$swal.fire({
                title: 'Está seguro de Eliminar este segmento?, se eliminaran tambien todas las categorias e información adicinal asociada a este.',
                showCancelButton: true,
                confirmButtonText: 'Aceptar',
                denyButtonText: `Cancelar`,
                icon: 'warning',
                }).then((result) => {
                
                if (result.isConfirmed) {

                    this.LoadingOpen();

                    this.$http.delete('architectures/'+ id,
                    {
                        headers:{"Authorization": 'Bearer '+this.getCrypto()
                    }
                    }).then(response => {
                            this.getSegments();
                            this.LoadingClose();

                            this.SucessAlert(
                                'Eliminado',
                                'segmento eliminado con éxito'
                            )
                    }).catch(error => {
                        this.LoadingClose();
                    
                        this.ErrorAlert('Error al eliminar el segmento')
                    })

                } 
                })


        },

        getSegments(){
            this.loadingSegments = true;
            this.$http.get('dimensions/segments/'+this.getClientSelect().id + '?page=' + this.currentPage).then(response => {
                this.Segments = response.data.data
                console.log('segments', this.Segments)
                this.total_pages = response.data.total_pages
                
                this.loadingSegments = false;
            }).catch(error => {
                this.ToastError(error.data.status +' '+error.data.error)
                // console.log('ERROR AL CONSULTAR LOS SEGMENTOS', error)
            })
        },

   
    },
    
    beforeMount(){
        this.getSegments()
    }
}
</script>